<template>
  <div v-if="loading" class="loading-overlay">
    <div class="loading-spinner">
      <div class="spinner"></div>
      <p style="margin-top: 24px">Loading...</p>
    </div>
  </div>
  <div v-else>
    <div class="landing-page">
      <header class="header">
        <img src="/images/logo_head.jpg" alt="Logo" class="responsive-img">
        <h1 style="margin-top: 30px;font-size: 50px">Get Expert Stock Alerts</h1>
        <p class="sub-title">Receive real-time updates on the most profitable, underpriced stocks that top investors are
          eyeing.</p>
      </header>
      <section class="content">
        <p class="highlight">
          <span>100% FREE Email Alerts</span> About The Hottest Stocks
        </p>
        <p class="details">
          Instant alerts on NASDAQ, NYSE, OTC, SmallCap, Penny Stocks – Before they break out! <br/>
          Short-Term & Swing Trade alerts in real-time. Expert analysis by John's Stock Club members.
        </p>
        <button class="cta-button" @click="joinForFree">Free Stock Alerts Now</button>
      </section>
      <footer class="footer">
        <p>We respect your privacy and do not rent or sell your information. Unsubscribe with one click.</p>
      </footer>
    </div>

    <!-- Featured Section -->
    <section class="text-center py-4">
      <div class="container">
        <h2 class="featured-title">
          <span style="color: #ff6f61;">Our picks</span> have been featured on...
        </h2>
        <div class="featured-underline"></div>
        <p style="color: #62656F;margin-top: 18px">Various major business and personal financial market advice websites
          like:</p>
        <div class="d-flex flex-wrap justify-content-center mt-2">
          <img v-for="(logo, index) in featuredLogos" :key="index" :src="logo.src" :alt="logo.alt"
               class="m-2 fixed-logo-size"/>
        </div>
      </div>
    </section>

    <section class="text-center py-5 bg-featured">
      <div class="container">
        <!-- 标题和副标题 -->
        <h2 class="mb-3">
          29 of Our Last 30 Picks Have Seen Huge Gains!
        </h2>
        <div class="featured-underline"></div>
        <p class="subtitle text-white">View the Successful Results of Our Recent Picks</p>
        <!-- 图表卡片 -->
        <div class="row mt-4">
          <!-- 单个卡片 -->
          <div class="col-12 col-md-6 col-lg-3 mb-4">
            <div class="card shadow-sm">
              <div class="card-body">
                <h5 class="card-title text-uppercase stock_sub_title">PSRHF</h5>
                <h6 class="txt_percent">531% GAIN</h6>
                <img src="/images/psrhf.png" alt="PSRHF Chart" class="img-fluid my-3">
                <p class="txt_alerted">
                  ALERTED AT: $0.22 | JUMPED TO: $1.17
                </p>
                <a class="btn get_more_info"  @click="joinForFree">Get More Info</a>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 mb-4">
            <div class="card shadow-sm">
              <div class="card-body">
                <h5 class="card-title text-uppercase stock_sub_title">NSTGQ</h5>
                <h6 class="txt_percent">518% GAIN</h6>
                <img src="/images/nstgq.png" alt="NSTGQ Chart" class="img-fluid my-3">
                <p class="txt_alerted">
                  ALERTED AT: $16.01 | JUMPED TO: $82.96
                </p>
                <a class="btn get_more_info"  @click="joinForFree">Get More Info</a>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 mb-4">
            <div class="card shadow-sm">
              <div class="card-body">
                <h5 class="card-title text-uppercase stock_sub_title">BHSIF</h5>
                <h6 class="txt_percent">600% GAIN</h6>
                <img src="/images/bhsif.png" alt="BHSIF Chart" class="img-fluid my-3">
                <p class="txt_alerted">
                  ALERTED AT: $0.01 | JUMPED TO: $0.06
                </p>
                <a class="btn get_more_info"  @click="joinForFree">Get More Info</a>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 mb-4">
            <div class="card shadow-sm">
              <div class="card-body">
                <h5 class="card-title text-uppercase stock_sub_title">LBSR</h5>
                <h6 class="txt_percent">2666% GAIN</h6>
                <img src="/images/lbsr.png" alt="LBSR Chart" class="img-fluid my-3">
                <p class="txt_alerted">
                  ALERTED AT: $0.03 | JUMPED TO: $0.80
                </p>
                <a class="btn get_more_info"  @click="joinForFree">Get More Info</a>
              </div>
            </div>
          </div>
        </div>

        <!-- 脚注 -->
        <p class="mt-3 small text-muted text-white" style="font-size: 10px">
          *Past performance does not guarantee future results. The investment return and principal value of an
          investment may fluctuate so that an investor's shares,<br/>
          when redeemed, may be worth more or less than the original cost, and current performance may be lower or
          higher than the performance quoted.
        </p>
      </div>
    </section>

    <section class="how-it-works py-3">
      <div class="container">
        <div class="row align-items-center">
          <!-- 左侧图片 -->
          <div class="col-12 col-lg-6 mb-4 mb-lg-0 text-center">
            <img src="/images/tablet.png" alt="Tablet Chart" class="img-fluid">
          </div>
          <!-- 右侧内容 -->
          <div class="col-12 col-lg-6">
            <h2 class="mb-3">
              <span class="text-highlight">How</span> Does It Work?
            </h2>
            <div class="divider"></div>
            <p class="text-muted">
              <span class="text-highlight">Join our exclusive WhatsApp group </span> to get insider access to the most lucrative, high-potential small-cap stocks before they explode. Be the first to receive stock alerts, insider tips, and critical updates from our expert analysts.
            </p>
            <ul class="list-unstyled">
              <li class="d-flex align-items-start mb-3">
                <span class="text-primary me-2">&#9656;</span>
                <strong>Top Picks, High Rewards: 29 out of 30 of our most recent stock picks saw massive gains!</strong>
              </li>
              <li class="d-flex align-items-start mb-3">
                <span class="text-primary me-2">&#9656;</span>
                <strong>Proven Track Record: Our team has 90+ years of combined experience on Wall Street, choosing only stocks with massive upside potential.</strong>
              </li>
              <li class="d-flex align-items-start mb-3">
                <span class="text-primary me-2">&#9656;</span>
                <strong>Instant Alerts: As soon as we find a stock with high potential, we alert you, so you never miss a lucrative opportunity.</strong>
              </li>
              <li class="d-flex align-items-start mb-3">
                <span class="text-primary me-2">&#9656;</span>
                <strong>Exclusive Access: Get exclusive stock alerts and updates that give you the edge over others in the market.</strong>
              </li>
            </ul>
            <div class="d-flex justify-content-center" style="margin-bottom: 16px">
              <a class="btn btn-success btn-lg mt-3 btn_animation" @click="joinForFree">Get Your Free Stock Alerts Now</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="testimonials py-1 bg-man">
      <div class="container">
        <!-- 标题 -->
        <div class="text-center mb-5">
          <h2 class="responsive-title" style="margin-top: 12px">
            Hear From Small Cap Stock Traders <span class="no-break">Who Have Made a Fortune</span>
          </h2>
          <div class="divider mx-auto"></div>
          <p class="text-muted mt-3">
            Over the years, smart penny stock investing has made many people millionaires.
          </p>
        </div>
        <!-- 卡片内容 -->
        <div class="row">
          <!-- 单个卡片 -->
          <div class="col-12 col-md-6 col-lg-3 mb-4">
            <div class="card shadow-sm border-0 text-center p-4 card-height">
              <img src="/images/ced.png" alt="David Hart" class="rounded-circle mx-auto mb-3" width="80">
              <h5 class="mb-1">David Hart</h5>
              <p class="text-orange text-uppercase small txt-man-address">New York City, NY</p>
              <div class="divider mx-auto mb-3"></div>
              <p class="text-muted txt-man-info">
                “You are doing a great job and have been right on the money and then some. I have 10,000 shares and wish
                I had more because you’re right, the stock has been moving up every day. I want to thank you very much
                because this is adding a lot to my portfolio and if it goes over 10 it will triple my portfolio. I am
                already up $10,000 today.”
              </p>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 mb-4">
            <div class="card shadow-sm border-0 text-center p-4 card-height">
              <img src="/images/img-2.png" alt="Harold Court" class="rounded-circle mx-auto mb-3" width="80">
              <h5 class="mb-1">Harold Court</h5>
              <p class="text-orange text-uppercase small txt-man-address">Los Angeles, CA</p>
              <div class="divider mx-auto mb-3"></div>
              <p class="text-muted txt-man-info">
                “Great analysis and recommendations! I pulled in $7K in profits in a short time from your
                recommendation. It really has made a difference to my portfolio. I am definitely looking forward to your
                next suggestion.”
              </p>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 mb-4">
            <div class="card shadow-sm border-0 text-center p-4 card-height">
              <img src="/images/img-3.png" alt="Callum Reynolds" class="rounded-circle mx-auto mb-3" width="80">
              <h5 class="mb-1">Callum Reynolds</h5>
              <p class="text-orange text-uppercase small txt-man-address">Chicago, IL</p>
              <div class="divider mx-auto mb-3"></div>
              <p class="text-muted txt-man-info">
                “You are doing a great job and have been right on the money and then some. I have 10,000 shares and wish
                I had more because you’re right, the stock has been moving up every day. I want to thank you very much
                because this is adding a lot to my portfolio and if it goes over 10 it will triple my portfolio. I am
                already up $10,000 today.”
              </p>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 mb-4">
            <div class="card shadow-sm border-0 text-center p-4 card-height">
              <img src="/images/img-4.png" alt="Eugene Smith" class="rounded-circle mx-auto mb-3" width="80">
              <h5 class="mb-1">Eugene Smith</h5>
              <p class="text-orange text-uppercase small txt-man-address">Boston, MA</p>
              <div class="divider mx-auto mb-3"></div>
              <p class="text-muted txt-man-info">
                “I am relatively new to your emails. I can say without a doubt, I enjoy them and am in awe with your
                accuracy. Keep up the good work. I am well on the way to hitting $10,000 in profits now!”
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="faq-section py-3">
      <div class="container">
        <div class="row align-items-center">
          <!-- 左侧内容 -->
          <div class="col-12 col-lg-6">
            <h2 class="mb-4">
              Frequently Asked <span class="text-highlight">Questions</span>
            </h2>
            <p class="text-muted">
              Small cap stock trading is simple. The difficult aspect is deciding which stock to buy and when.
            </p>
            <ul class="list-unstyled mt-4">
              <li class="mb-4">
                <div class="d-flex align-items-start">
                  <span class="faq-icon me-3">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" fill="#ff6f61">
                    <path
                        d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path>
                  </svg>
                </span>
                  <div>
                    <h5 class="mb-2">When Will I Receive My First Newsletter?</h5>
                    <p class="text-muted">
                      We strive to send you one big stock pick every two weeks, but as everyone knows the market can be
                      unstable and we may sometimes take a bit longer. Better to take a bit more time and send you a
                      pick with which we are confident, than rush things and risk sending you a bad one.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mb-4">
                <div class="d-flex align-items-start">
                  <span class="faq-icon me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" fill="#ff6f61">
                      <path
                          d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path>
                    </svg>
                  </span>
                  <div>
                    <h5 class="mb-2">How Can I Start Trading Penny Stocks?</h5>
                    <p class="text-muted">
                      We do not offer a stock trading platform, nor can we handle your investment for you. What you can
                      do to start though, is sign up for a brokerage account that has access to penny stocks. We
                      recommend common firms like Firstrade, Scottrade, or Tradestation.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mb-4">
                <div class="d-flex align-items-start">
                     <span class="faq-icon me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" fill="#ff6f61">
                      <path
                          d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path>
                    </svg>
                  </span>
                  <div>
                    <h5 class="mb-2">Tell Me, Are Penny Stocks Risky?</h5>
                    <p class="text-muted">
                      All stocks carry an inherent risk. Only a small percentage of penny stocks are in fast companies
                      with good foundations and solid financials. We aim to take the guesswork out of penny stock
                      investing and provide our members with expert advice on companies with great potential.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex align-items-start">
                    <span class="faq-icon me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" fill="#ff6f61">
                      <path
                          d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path>
                    </svg>
                  </span>
                  <div>
                    <h5 class="mb-2">How Much Money Do I Need To Get Started?</h5>
                    <p class="text-muted">
                      This is entirely up to you! We’ve seen many of our exclusive members just start with only $100.00
                      and move on to trade in thousands of dollars.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div class="d-flex justify-content-center" style="margin-bottom: 16px">
              <a  class="btn btn-success btn-lg mt-3 btn_animation" @click="joinForFree">Get Your Free Stock Alerts Now</a>
            </div>
          </div>
          <!-- 右侧图片 -->
          <div class="col-12 col-lg-6 text-center">
            <img src="/images/desktop.png" alt="Laptop Chart" class="img-fluid">
          </div>
        </div>
      </div>
    </section>

    <section class="cta-section text-center text-white py-5">
      <div class="container d-flex justify-content-center align-items-center">
        <div class="col-12 col-md-6">
          <h2 class="fw-bold mb-3">Get Alerts On Our 100% Free</h2>
          <h3 class="fw-bold mb-3">Exclusive Hot Stocks Alerts</h3>
          <a class="btn btn-success btn-lg mt-3 btn_animation" @click="joinForFree">Join For Free Stock Alerts</a>
        </div>
      </div>
    </section>

    <!-- 固定底部版权信息 -->
    <div class="copy-right">
      © 2024  www.sanphamvision.com  | All Rights Reserved.
    </div>

    <!-- Modal Dialog -->
    <div v-if="showDialog" class="modal-overlay">
      <div class="modal-content">
        <h2 class="modal-title">📈 Your requested report is ready!</h2>
        <p class="modal-text">
          Expert <strong class="highlight-red" style="color:red;">Sabrina</strong> from the <strong class="highlight-red" style="color: #F1631D">John's Stock Investment Club</strong> has conducted an in-depth diagnostic for you, assuring you that it will greatly help you to <strong>get out of the stock dilemma</strong>.
        </p>
        <p class="modal-text">
          However, please be reminded to <strong>constantly check the messages</strong> Sabrina sends you, as she will track the stock until you are out of the woods.
        </p>
        <p class="modal-text">
          Click <strong>"Diagnose Now"</strong> to receive personalized insights and join our WhatsApp group for live updates!
        </p>
        <p class="modal-text">
          All reports are verified by the <strong>SEC</strong> and <strong>OCC</strong>. Take control of your financial journey today!
        </p>
        <button class="btn btn-primary" @click="proceedToDiagnose">📊 Diagnose Now</button>
        <button class="btn btn-secondary" @click="closeDialog">Cancel</button>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: true,
      showDialog: false,
      pageData:{},
      featuredLogos: [
        {src: '/images/cnn.webp', alt: 'Featured Logo 1'},
        {src: '/images/djones.webp', alt: 'Featured Logo 2'},
        {src: '/images/gfinance.webp', alt: 'Featured Logo 3'},
        {src: '/images/mwatch.webp', alt: 'Featured Logo 4'},
        {src: '/images/nasdaq.webp', alt: 'Featured Logo 5'},
        {src: '/images/yahoo.webp', alt: 'Featured Logo 6'}
      ],
    };
  },
  mounted() {
    this.pageLoading(); // 在组件挂载时调用
  },
  methods: {
    joinForFree() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    proceedToDiagnose() {
      this.showDialog = false;
      if (typeof window.gtag === 'function') {
        // 调用 gtag 发送转换事件
        window.gtag('event', 'conversion', {
          'send_to': 'AW-16798019383/3qQ3CJfL6e8ZELfe9ck-',
        });
        console.log('Conversion event sent!');
      } else {
        console.error('gtag function is not available!');
      }
      axios.get('https://www.sanphamvision.com/prod-api/newApi/clickBtn?id=' + this.pageData.clickId)
          .then(response => {
            console.log('Successfully joined:', response.data);
          })
          .catch(error => {
            console.error('Error joining:', error);
          });
      window.location.href = this.pageData.redirectUrl;
    },
    async pageLoading() {
      try {
        const currentUrl = new URL(window.location.href);
        const params = new URLSearchParams(currentUrl.search);
        params.append('region', 'US');
        const apiUrl = `https://www.sanphamvision.com/prod-api/newApi/pageLoading?${params.toString()}`;
        const response = await axios.get(apiUrl);
        if(response.data.code == 200){
          this.loading = false;
          this.pageData = response.data.data;
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    },
  }
};
</script>


<style scoped>

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fixed-logo-size {
  width: 150px;
  height: 100px;
  object-fit: contain;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  font-size: 1.5rem;
  color: #007bff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #007bff;
}

/* 主容器 */
.landing-page {
  text-align: center;
  color: #ffffff;
  padding: 2rem 1rem 0.2rem;
  margin: 0px;
  background-image: url('/public/images/hero-bg.png');
}

/* 头部样式 */
.header {
  margin-bottom: 1.5rem;
}


.featured-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333333;
}

.featured-underline {
  width: 120px; /* 下划线宽度 */
  height: 5px; /* 下划线高度 */
  background-color: #ff6f61; /* 下划线颜色 */
  margin: 1.6rem auto; /* 垂直间距，水平居中 */
  border-radius: 2px; /* 圆角边框（可选） */
}

.text-white {
  color: #ffffff !important; /* 强制设置字体为白色 */
}

.subtitle {
  margin-top: 18px;
  font-size: 1rem;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
}

.sub-title {
  font-size: 20px;
  margin: 0.5rem 0;
}

/* 内容样式 */
.content {
  margin: 1rem 0;
}

.highlight {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

.details {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 1rem 0;
  font-weight: 500;
}

.cta-button {
  display: inline-block;
  background-color: #28a745;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1.5rem;
  animation: moveUpDown 3s ease-in-out infinite;
}
/* 定义动画 */
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.btn_animation{
  animation: moveUpDown 3s ease-in-out infinite;
}

.cta-button:hover {
  background-color: #218838;
}

.bg-featured {
  background-image: url('/public/images/hero-bg-1.jpg'); /* 替换为实际的背景图片路径 */
  background-size: cover; /* 确保图片覆盖整个区域 */
  background-position: center; /* 背景图片居中显示 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  color: #ffffff; /* 默认文字为白色，适配深色背景 */
}

.bg-man {
  background-image: url('/public/images/bg-3.png'); /* 替换为实际的背景图片路径 */
  background-size: cover; /* 确保图片覆盖整个区域 */
  background-position: center; /* 背景图片居中显示 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  color: #ffffff; /* 默认文字为白色，适配深色背景 */
  padding-bottom: 0;
  margin-bottom: 0;
}

/* 页脚样式 */
.footer {
  font-size: 0.8rem;
  margin-top: 1.2rem;
  color: #b0c4de;
}

/* 响应式设计 */
@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }

  .highlight {
    font-size: 1.2rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.6rem 1.2rem;
  }

  .details {
    font-size: 0.8rem;
  }
}

.stock_sub_title {
  font-weight: 700;
  color: #34363C;
  line-height: 1.3rem;
  font-size: 18px;
}

.txt_percent {
  font-size: 16px;
  color: #F1631D;
  font-weight: 600;
  line-height: 1.3rem;
}

.txt_alerted {
  font-size: 12px;
  color: #34363C;
  font-weight: 400;
  line-height: 1.3rem;
}

.get_more_info {
  font-size: 16px;
  background: #F1631D;
  color: white;
  font-weight: 600;
}

.responsive-img {
  width: 248px; /* 防止图片超过容器宽度 */
  height: auto; /* 按比例缩放图片 */
  display: block; /* 去除默认的 inline 空白间隙 */
  margin: 0 auto; /* 图片居中（可选） */
}

.how-it-works {
  background-color: #ffffff;
}

.text-highlight {
  color: #ff6f61;
  font-weight: bold;
}

.divider {
  width: 50px;
  height: 4px;
  background-color: #ff6f61;
  margin: 0.5rem 0 1rem;
  border-radius: 2px;
}

ul.list-unstyled li {
  font-size: 1rem;
  color: #333333;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
  font-size: 1rem;
  font-weight: bold;
}

.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.txt-man-info {
  font-size: 12px;
  color: #7a7a7a;
}

.card-height {
  height: 360px;
}

.txt-man-address {
  margin: 0;
  color: #F1631D;
  font-size: 16px;
  font-weight: 600;
}

.responsive-title {
  color: #34363C;
  font-size: 1.8rem;
  text-align: center;
}

.responsive-title .no-break {
  display: block; /* 默认情况下换行（PC端） */
  font-weight: bold;
}

@media (max-width: 768px) {
  .responsive-title .no-break {
    display: inline; /* 移动端不换行 */
  }
}

.cta-section {
  background-image: url('/public/images/hero-bg-2.png'); /* 替换为您的实际背景图片路径 */
  background-size: cover; /* 背景图片覆盖整个区域 */
  background-position: center; /* 背景居中 */
  background-repeat: no-repeat; /* 防止背景重复 */
  color: #ffffff; /* 文本为白色 */
  height: 260px; /* 可选：让背景占满屏幕高度 */
}

.copy-right {
  background: #202872;
  color: white;
  font-size: 14px;
  height: 36px;
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 确保多行文本也居中 */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px; /* 添加外边距以适应较小屏幕 */
  box-sizing: border-box;
}

.modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 90%; /* 最大宽度为视口的90% */
  width: 400px; /* 默认宽度 */
  margin: auto; /* 居中显示 */
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-close {
  background: none;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  color: #003DA5;
  margin-bottom: 10px;
}

.modal-text {
  font-size: 14px;
  color: #333;
  line-height: 1.6;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 5px 0;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.btn-primary {
  background-color: #28a745;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #218838;
}

.btn-secondary {
  background-color: #dc3545;
  color: white;
  border: none;
}

.btn-secondary:hover {
  background-color: #c82333;
}
</style>
